<template>
  <v-app>
    <material-alert />
    <default-bar />
    <default-drawer />
    <default-view />
  </v-app>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "DefaultLayout",

  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        "./AppBar"
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        "./drawer/Drawer.vue"
      ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        "./View"
      ),
  },
  computed: {
    ...sync("app", ["mini", "items"]),
  },
  watch: {
    mini(val) {
      console.log({ val });
      if (val) {
        document.removeEventListener("click", this.listenMini);
      } else {
        document.addEventListener("click", this.listenMini, false);
      }
    },
    $route(val) {
      console.log("route", val);
      this.checkCollapse();
    },
  },
  mounted() {
    if (this.mini) {
      this.checkCollapse();
    }
  },
  methods: {
    listenMini() {
      if (!this.mini) this.mini = true;
    },
    checkCollapse() {
      this.items.forEach((el) => {
        if (el.items) {
          this.$nextTick(() => (el.items.active = false));
        }
      });
    },
  },
};
</script>
<style lang="scss"></style>
